import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className={'pageContent donateForm'}>
          <Helmet title={'Donate - Zonta Club of Toronto'} />
          <div className="wrapper">
            <iframe title='Donation Form powered by CanadaHelps' src='https://www.canadahelps.org/en/dn/27428' />
          </div>
        </div>
      </Layout> 
    )
  }
}

export default RootIndex
